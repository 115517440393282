import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  output,
  QueryList,
  runInInjectionContext,
  Signal,
  ViewChildren,
} from '@angular/core';
import { complexFormErrors, semiComplexFormErrors } from '@common/rxjs';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CountryISO,
  NgxIntlTelInputModule,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { MlkButtonComponent } from '@shared/ui';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BaseFormClass } from '@shared/components/form/base-form-class';

@Component({
  selector: 'app-partner-attendee-form',
  imports: [
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    MlkButtonComponent,
    NgbTooltip,
  ],
  templateUrl: './partner-attendee-form.component.html',
  styleUrl: './partner-attendee-form.component.scss',
})
export class PartnerAttendeeFormComponent
  extends BaseFormClass
  implements OnInit
{
  formErrorFromServer = input.required<complexFormErrors>();
  partnerFormServerErrorObject: Signal<semiComplexFormErrors> = computed(() =>
    this.getOnlyErrorsRelatedToThePartnerFormGroups()
  );
  partnerArrayOfFormGroups = input.required<AbstractControl[]>();
  relatedFormArrayName = input.required<string>();
  relatedFormGroup = input.required<FormGroup>();
  searchCountryField = input.required<SearchCountryField[]>();
  countryISO = input.required<CountryISO>();
  removePartnerEvent = output<number>();
  injector = inject(Injector);

  @ViewChildren('singleFormGroup') allFormGroups:
    | QueryList<ElementRef>
    | undefined;

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.associateEachErrorObjectToTheCorrespondingFormGroup(
          this.partnerFormServerErrorObject(),
          this.partnerArrayOfFormGroups(),
          0
          //there is only one form partner for the moment
        );
      });
    });
  }

  private getOnlyErrorsRelatedToThePartnerFormGroups(): semiComplexFormErrors {
    return (this.formErrorFromServer()['partner'] ??
      []) as semiComplexFormErrors;
  }
}
